import React from 'react'
import PropTypes from 'prop-types'
import ContactForm from '../ContactForm'

const ContactPageTemplate = ({ title, subtitle }) => <>
  <section className='mw7 center avenir'>
    <div className='mt3'>
      <div className='pa3 measure center bb'>
        <h1 className='f2 lh-title fw4 mb3 mt0 pt3 bw2 avenir'>{title}</h1>
        <div style={{marginTop: 80}}>
          <p><span className='f3 mid-gray lh-title avenir fw2'>Mail us: </span> hello@keralapsc.app</p>
          <p> <span className='f3 mid-gray lh-title avenir fw2'>Phone:</span> 9633220972</p>
        </div>
      </div>
      {/* <ContactForm /> */}
    </div>
  </section>
</>

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ContactPageTemplate
